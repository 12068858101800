<template>
<div style="width: 120px; display:inline-table;">
    <datepicker v-model="date" id="date-picker" name="date-picker" class="date-picker" format="yyyy-MM-dd" @input="updateSelf($event)"></datepicker>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
export default {
    props: ['parentDate'],
    components: {
        Datepicker
    },
    data: () => ({
        date: ''
    }),
    mounted() {
        // this.date = this.formatDate()
        
        if (this.parentDate) {            
            this.date = this.parentDate
        }
    },

    watch: {
        parentDate() {
            this.date = this.parentDate            
        }
    },
    methods: {
        // datepicker으로 날짜 선택했을 시에 사용한 페이지의 v-model에 데이터 들어감
        updateSelf(name) {
            this.$emit('input', name)
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate() {
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        }
    }
}
</script>
