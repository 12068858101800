<template>
    <div>

    </div>
</template>
<script>
export default {
    created () {
        alert('없는 페이지입니다.')
        history.back()
        // this.$router.push('/')
    }
}
</script>