import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import { _axios } from '../plugins/axios';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo :{
      auth: localStorage.getItem('auth'),//권한 
      accessToken: localStorage.getItem('accessToken'), //토큰
      id : localStorage.getItem('id'), //id
      idx: localStorage.getItem('idx'), //idx
      idxCrmCenter: localStorage.getItem('idxCrmCenter') // 센터 ID
    },
  },
  mutations: {
    setUserInfo(state, payload){
        state.userInfo = payload;
        for(let key in payload){
          localStorage.setItem(key,payload[key]);
        }
    }
  },
  getters:{
    getUserInfo: (state) =>{
      return state.userInfo;
    }

  },
  actions: {
    /**
     * @description : crmLogin
     */
    crmLogin({commit,/* state */},payload){
      _axios.get('/api/v1/login', { params : payload })
        .then(res =>{
          if(res.data.err === 0){
            const { accessToken, authority, id, idx, idxCrmCenter} = res.data;
            const userInfo = {
              accessToken: accessToken,
              auth: authority,
              id: id,
              idx: idx,
              idxCrmCenter: (authority === 'MASTER' || authority === 'ADMIN') ? 0 : idxCrmCenter
            };

            commit('setUserInfo',userInfo);

            if(userInfo.auth === 'STAFF' || userInfo.auth ==='CENTERSTAFF'){
              router.push('/counselor');
            }
            else if(userInfo.auth === 'MASTER' || userInfo.auth === 'ADMIN' ||  userInfo.auth === 'CENTERDIRECTOR'){
              router.push('/origin');
            }
          }
          else{
            alert(res.data.result);
          }
        })
        .catch(err => {
          console.error('crmLogin : ',err)
          alert(err)
        })
    }
  },
  plugins: [createPersistedState()],
})
