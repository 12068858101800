import Vue from "vue";
import VueRouter from "vue-router";
import error404 from "@/404";

Vue.use(VueRouter);

// 페이지 접근 권한 체크
const requirePath = (to, from, next) => {
  if (localStorage.getItem("accessToken")) {
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");

    var params = {
      accessToken: accessToken,
      id: id,
    };

    Vue.axios.get("/api/v1/login/access-check", {
        params: params,
      })
      .then((res) => {
        if (to.fullPath.includes('/admin/notice/modify')) { // 공지사항 수정 및 등록은 master만 가능
          if (res.data.authority !== "MASTER") {
            alert("권한이 없습니다.");
            history.back()
          } else {
            next();
          }
        }
        else if (to.fullPath.includes('/origin/notice/notice')) {
          // alert("띠용");
          if(to.fullPath.includes('/admin/notice/register')){
            
          if (res.data.authority !== "MASTER" || res.data.authority !== "ADMIN") {
            alert(res.data.authority);
            alert("권한이 없습니다.");
            history.back()
          } else {
            next();
          }
        }else {
          next();
        }
          // 본원 페이지는 마스터, 어드민만 들어갈 수 있음
        }
        // 관리자 페이지는 마스터만 들어갈 수 있음
        else if (to.fullPath.includes('/admin')) {
          if (res.data.authority !== "MASTER") {
            alert("권한이 없습니다.");
            history.back()
          } else {
            next();
          }
          // 본원 페이지는 마스터, 어드민만 들어갈 수 있음
        } else if (to.fullPath.includes('/origin')) {

          /// 상담일지 상세보기는 스태프도 볼수 있어야함
          if(to.fullPath.includes('/counselor_journey_info')){
            next();
          } else {
            if (res.data.authority !== "MASTER" && res.data.authority !== "ADMIN" && res.data.authority !== "CENTERDIRECTOR"&& res.data.authority !== "STAFF") {
              alert("권한이 없습니다.");
              history.back()
            } else {
              next();
            }
          }

          // 상담사 페이지는 본원 Staff, 협약Staff만 들어갈 수 있음
        } else if (to.fullPath.includes('/counselor')) {
          if(to.fullPath.includes('/convention/counselor')) {
            if (res.data.authority !== "CENTERDIRECTOR") {
              alert("권한이 없습니다.");
              history.back()
            } else {
              next();
            }
          } 
          /* 상담일지 수정은 관리자도 수정할 수 있도록 변경 */
          else if(to.path.includes('/counselor/journal_modify')){
            // if(res.data.authority === 'MASTER'){
              next();
            // }else{
              // alert("권한이 없습니다.");
              // history.back();
            // }
          }
          else {

            if (res.data.authority !== "STAFF" && res.data.authority !== "CENTERSTAFF") {
              alert("권한이 없습니다.");
              history.back()
            } else {
              next();
            }
          }
          // 협약센터는 센터장만 들어갈 수 있음
        } else if (to.fullPath.includes('/convention')) {
          if (res.data.authority !== "CENTERDIRECTOR") {
            alert("권한이 없습니다.");
            history.back()
          } else {
            next();
          }
        } else {
          history.back()
        }
      })
      .catch(err => {
        console.log(err)
        if(err.message === 'Network Error') {
            alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
            window.location.href='/login';
        } else if (err.response.data.path == '/login/msg') {
          alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
          window.location.href='/login'
        } else {
          alert('권한이 없습니다.')
          history.back()
        }
      });
  } else {
    alert("권한이 없습니다.");
    history.back()
  }
};

const routes = [
  {
    // 404 페이지
    path: "*",
    component: error404,
  },
  // 어드민을 /경로로 넣고 기본 url 탈때 리스트로 자동 redirect
  {
    path: "/",
    redirect: (to) => {
      localStorage.removeItem("accessToken");
      return { path: "/login", query: null };
    },
  },
  { path: "/admin", redirect: { name: "admin-account" } },
  { path: "/origin", redirect: { name: "origin-consultSchedule" } },
  { path: "/counselor", redirect: { name: "counselor-notice" } },
  { path: "/convention", redirect: { name: "convention-consultSchedule" } },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/login.vue"),
  },
  {
    path: "/regCounselor",
    name: "RegCounselor",
    component: () => import("@/pages/regCounselor.vue"),
  },
  {
    path: "/regCenter",
    name: "RegCenter",
    component: () => import("@/pages/regCenter.vue"),
  },
  {
    // 관리자 관리 페이지
    path: "/admin",
    name: "Admin",
    component: () => import("@/pages/admin.vue"),
     beforeEnter: requirePath,

    children: [
      {
        // 관리자계정
        path: "account",
        name: "admin-account",
        component: () => import("@/pages/admin/account.vue"),
      },
      {
        // 관리자등록
        path: "account/register",
        name: "admin-account-register",
        component: () => import("@/pages/admin/account/register.vue"),
      },
      {
        // 관리자수정
        path: "account/modify/:idx",
        name: "admin-account-modify",
        component: () => import("@/pages/admin/account/modify.vue"),
      },
      {
        // 센터현황
        path: "center",
        name: "admin-center",
        component: () => import("@/pages/admin/center.vue"),
      },
      {
        // 상담일지승인
        path: "confirmConsult",
        name: "admin-confirmConsult",
        component: () => import("@/pages/admin/confirmConsult.vue"),
      },
      {
        // 협약센터승인
        path: "confirmConvention",
        name: "admin-confirmConvention",
        component: () => import("@/pages/admin/confirmConvention.vue"),
      },
      {
        // 상담사승인
        path: "confirmCounselor",
        name: "admin-confirmCounselor",
        component: () => import("@/pages/admin/confirmCounselor.vue"),
      },
    ],
  },

  {
    // 본원 관리 페이지
    path: "/origin",
    name: "Origin",
    component: () => import("@/pages/origin.vue"),
    beforeEnter: requirePath,

    children: [
      {
        //공지등록
        path:"notice/register",
        name:"admin-notice-register",
        component: () => import("@/pages/origin/notice/register.vue"),
      },
      {
        //공지수정
        path:"notice/modify/:idx",
        name:"admin-notice-modify",
        component: () => import("@/pages/origin/notice/modify.vue")
      },
      {
        //공지상세
        path:"notice_detail/:id",
        name:"origin-notice-detail",
        component: () => import("@/pages/origin/notice/detail.vue"),
      },
      {
        //공지사항
        path: "notice",
        name: "admin-notice",
        component: () => import("@/pages/origin/notice/notice.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule",
        name: "origin-consultSchedule",
        component: () => import("@/pages/origin/consult/consultSchedule.vue"),
      },
      {
        // 상담일정(테스트)
        path: "consult_schedule_my_modal",
        name: "origin-consultScheduleMyModal",
        component: () => import("@/pages/origin/consult/consultScheduleMyModal.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule_button",
        name: "origin-consultScheduleButton",
        component: () => import("@/pages/origin/consult/consultScheduleButton.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule_register",
        name: "origin-consultScheduleRegister",
        component: () => import("@/pages/origin/consult/consultScheduleRegister.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule_modify",
        name: "origin-consultScheduleModify",
        component: () => import("@/pages/origin/consult/consultScheduleModify.vue"),
      },
      {
        // 상담예약관리
        path: "consult_reserve",
        name: "origin-consultReserve",
        component: () => import("@/pages/origin/consult/consultReserve.vue"),
      },
      {
        // 상담예약관리
        path: "customer-service",
        name: "origin-customerService",
        component: () => import("@/pages/origin/customer/customerServiceManage.vue"),
      },
      {
        // 상담예약 상세정보
        path: "consult_reserve_detail",
        name: "origin-consultReserveDetail",
        component: () =>
          import("@/pages/origin/consult/consultReserveDetail.vue"),
      },
      {
        // 상담예약 등록
        path: "consult_reserve_register",
        name: "origin-consultReserveRegister",
        component: () =>
          import("@/pages/origin/consult/consultReserveRegister.vue"),
      },
      {
        // 상담예약 수정
        path: "consult_reserve_modify",
        name: "origin-consultReserveModify",
        component: () =>
          import("@/pages/origin/consult/consultReserveModify.vue"),
      },
      {
        // 내담자관리
        path: "person_manage",
        name: "origin-personManage",
        component: () => import("@/pages/origin/customer/personManage.vue"),
      },
      {
        // 내담자등록
        path: "person_manage_register",
        name: "origin-personManageRegister",
        component: () =>
          import("@/pages/origin/customer/personManageRegister.vue"),
      },
      {
        // 내담자수정
        path: "person_manage_modify",
        name: "origin-personManageModify",
        component: () =>
          import("@/pages/origin/customer/personManageModify.vue"),
      },
      {
        // 고객차트관리
        path: "customer_manage",
        name: "origin-customerManage",
        component: () => import("@/pages/origin/customer/customerManage.vue"),
      },
      {
        // 고객차트 상세보기
        path: "customer_manage_chart",
        name: "origin-customerManageChart",
        component: () =>
          import("@/pages/origin/customer/customerManageChart.vue"),
      },
      {
        // 고객등록
        path: "customer_manage_register",
        name: "origin-customerManageRegister",
        component: () =>
          import("@/pages/origin/customer/customerManageRegister.vue"),
      },
      {
        // 고객수정
        path: "customer_manage_modify",
        name: "origin-customerManageModify",
        component: () =>
          import("@/pages/origin/customer/customerManageModify.vue"),
      },
      {
        // B2C수납관리
        path: "customer_acceptance_b2c",
        name: "origin-customerAcceptanceB2C",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceB2C.vue"),
      },
      {
        // B2B수납관리
        path: "customer_acceptance_b2b",
        name: "origin-customerAcceptance",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceB2B.vue"),
      },
      {
        // B2C수납관리 등록
        path: "customer_acceptance_register_b2c",
        name: "origin-customerAcceptanceRegisterB2C",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceRegisterB2C.vue"),
      },
      {
        // B2C수납관리 수정
        path: "customer_acceptance_modify_b2c",
        name: "origin-customerAcceptanceModifyB2C",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceModifyB2C.vue"),
      },
      {
        // B2B수납관리 등록
        path: "customer_acceptance_register_b2b",
        name: "origin-customerAcceptanceRegisterB2B",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceRegisterB2B.vue"),
      },
      {
        // B2B수납관리 수정
        path: "customer_acceptance_modify_b2b",
        name: "origin-customerAcceptanceModifyB2B",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceModifyB2B.vue"),
      },
      {
        // B2C수납상세조회
        path: "customer_acceptance_view_b2c",
        name: "origin-customerAcceptanceViewB2C",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceViewB2C.vue"),
      },
      {
        // B2B고객조회
        path: "customer_acceptance_client_b2b",
        name: "origin-customerAcceptanceClientB2B",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceClientB2B.vue"),
      },
      {
        // B2B수납상세조회
        path: "customer_acceptance_view_b2b",
        name: "origin-customerAcceptanceViewB2B",
        component: () =>
          import("@/pages/origin/customer/customerAcceptanceViewB2B.vue"),
      },
      {
        // 상담사관리
        path: "counselor_manage",
        name: "origin-counselorManage",
        component: () => import("@/pages/origin/counselor/counselorManage.vue"),
      },
      {
        // 상담사카드
        path: "counselor_manage_card",
        name: "origin-counselorManageCard",
        component: () =>
          import("@/pages/origin/counselor/counselorManageCard.vue"),
      },
      {
        // 상담사카드 등록
        path: "counselor_manage_register",
        name: "origin-counselorManageRegister",
        component: () =>
          import("@/pages/origin/counselor/counselorManageRegister.vue"),
      },
      {
        // 상담사카드 수정
        path: "counselor_manage_modify",
        name: "origin-counselorManageModify",
        component: () =>
          import("@/pages/origin/counselor/counselorManageModify.vue"),
      },
      {
        // 상담사 급여요율 등록
        path: "counselor_manage_pay_register",
        name: "origin-counselorManagePayRegister",
        component: () =>
          import("@/pages/origin/counselor/counselorManagePayRegister.vue"),
      },
      {
        // 상담사 급여요율 수정
        path: "counselor_manage_pay_modify",
        name: "origin-counselorManagePayModify",
        component: () =>
          import("@/pages/origin/counselor/counselorManagePayModify.vue"),
      },
      {
        // 상담일지관리
        path: "counselor_journey",
        name: "origin-counselorJourney",
        component: () =>
          import("@/pages/origin/counselor/counselorJourney.vue"),
      },
      {
        // 상담일지상세보기
        path: "counselor_journey_info",
        name: "origin-counselorJourneyInfo",
        component: () =>
          import("@/pages/origin/counselor/counselorJourneyInfo.vue"),
      },
      {
        // 센터관리
        path: "center_manage",
        name: "origin-centerManage",
        component: () => import("@/pages/origin/center/centerManage.vue"),
      },
      {
        // 센터정보 상세보기
        path: "center_manage_info",
        name: "origin-centerManageInfo",
        component: () => import("@/pages/origin/center/centerManageInfo.vue"),
      },
      {
        // 센터등록
        path: "center_manage_register",
        name: "origin-centerManageRegister",
        component: () =>
          import("@/pages/origin/center/centerManageRegister.vue"),
      },
      {
        // 센터수정
        path: "center_manage_modify",
        name: "origin-centerManageModify",
        component: () => import("@/pages/origin/center/centerManageModify.vue"),
      },
      {
        // EAP 상담접수
        path: "center_eap_register",
        name: "origin-centerEapRegister",
        component: () => import("@/pages/origin/center/centerEapRegister.vue"),
      },
      {
        // EAP 상담수정
        path: "center_eap_modify",
        name: "origin-centerEapModify",
        component: () => import("@/pages/origin/center/centerEapModify.vue"),
      },
      {
        // EAP 배정현황
        path: "center_eap_manage",
        name: "origin-centerEapManage",
        component: () => import("@/pages/origin/center/centerEapManage.vue"),
      },
      {
        //상담사 승인
        path: "confirm_counselor",
        name: "origin-confirmCounselor",
        component: () => import("@/pages/origin/center/confirmCounselor.vue"),
      },
      {
        // 기업목록
        path: "company_manage",
        name: "origin-companyManage",
        component: () => import("@/pages/origin/company/companyManage.vue"),
      },
      {
        // 기업 상세화면
        path: "company_manage_info",
        name: "origin-companyManageInfo",
        component: () => import("@/pages/origin/company/companyManageInfo.vue"),
      },
      {
        // 기업 등록
        path: "company_manage_register",
        name: "origin-companyManageRegister",
        component: () =>
          import("@/pages/origin/company/companyManageRegister.vue"),
      },
      {
        // 기업 수정
        path: "company_manage_modify",
        name: "origin-companyManageModify",
        component: () =>
          import("@/pages/origin/company/companyManageModify.vue"),
      },
      {
        // 통계 대시보드
        path: "statistic_dashboard",
        name: "origin-statisticDashboard",
        component: () =>
          import("@/pages/origin/statistic/statisticDashboard.vue"),
      },
      {
        // 상담통계
        path: "statistic_consult",
        name: "origin-statisticConsult",
        component: () =>
          import("@/pages/origin/statistic/statisticConsult.vue"),
      },
      {
        //기업별 통계
        path: "statistic_company",
        name: "origin-statisticCompany",
        component: () =>
          import("@/pages/origin/statistic/statisticCompany.vue"),
        props: true
      },
      {
        //급여 통계
        path: "statistic_salary",
        name: "origin-statisticSalary",
        component: () =>
          import("@/pages/origin/statistic/statisticSalary.vue"),
        props: true
      },
      {
        // B2C매출내역
        path: "sales_manage_b2c",
        name: "origin-salesManageB2C",
        component: () => import("@/pages/origin/sales/salesManageB2C.vue"),
      },
      {
        //B2B 매출내역
        path: "sales_manage_b2b",
        name: "origin-salesManageB2B",
        component: () => import("@/pages/origin/sales/salesManageB2B.vue"),
      },
      {
        // B2C지출관리
        path: "sales_manage_expend_b2c",
        name: "origin-salesManageExpendB2C",
        component: () => import("@/pages/origin/sales/salesManageExpendB2C.vue"),
      },
      // B2C지출등록
       {
        path: "sales_manage_register_b2c",
        name: "origin-salesManageRegisterB2C",
        component: () => import("@/pages/origin/sales/salesManageRegisterB2C.vue"),
      },
      {
        // B2C지출수정
        path: "sales_manage_modify_b2c",
        name: "origin-salesManageModifyB2C",
        component: () => import("@/pages/origin/sales/salesManageModifyB2C.vue"),
      },

      {
        // B2B지출관리
        path: "sales_manage_expend_b2b",
        name: "origin-salesManageExpendB2B",
        component: () => import("@/pages/origin/sales/salesManageExpendB2B.vue"),
      },
      {
        // B2B지출등록
        path: "sales_manage_register_b2b",
        name: "origin-salesManageRegisterB2B",
        component: () => import("@/pages/origin/sales/salesManageRegisterB2B.vue"),
      },
      {
        // B2B지출수정
        path: "sales_manage_modify_b2b",
        name: "origin-salesManageModifyB2B",
        component: () => import("@/pages/origin/sales/salesManageModifyB2B.vue"),
      },
      {
        // 상담사 급여내역
        path: "salary_manage",
        name: "origin-salaryManage",
        component: () => import("@/pages/origin/salary/salaryManage.vue"),
      },
      {
        // 상담사 상세 급여정보
        path: "salary_manage_info",
        name: "origin-salaryManageInfo",
        component: () => import("@/pages/origin/salary/salaryManageInfo.vue"),
      },
      {
        // 협약센터 급여내역
        path: "salary_convention",
        name: "origin-salaryConvention",
        component: () => import("@/pages/origin/salary/salaryConvention.vue"),
      },
      {
          // 기업 수정 -> 계약 등록
        path: "company_contract_register",
        name: "origin-companyContractRegister",
        component: () =>
          import("@/pages/origin/company/companyContractRegister.vue"),
      },
      {
        // 기업 수정 -> 계약 수정
        path: "company_contract_modify",
        name: "origin-companyContractModify",
        component: () =>
          import("@/pages/origin/company/companyContractModify.vue"),
      },
      
    ],
  },

  {
    // 상담사 관리 페이지
    path: "/counselor",
    name: "Counselor",
    component: () => import("@/pages/counselor.vue"),
     beforeEnter: requirePath,

    children: [
      {
        //공지상세
        path:"notice_detail/:id",
        name:"counselor-notice-detail",
        component: () => import("@/pages/counselor/noticeDetail.vue"),
      },
      {
        //공지사항
        path: "notice",
        name: "counselor-notice",
        component: () => import("@/pages/counselor/notice.vue"),
      },
      {
        // 고객차트 상세보기
        path: "counselor_customer_manage_chart",
        name: "counselor-customerManageChart",
        component: () =>
          import("@/pages/counselor/counselorCustomerManageChart.vue"),
      },
      {
        // 내담자차트
        path: "customer",
        name: "counselor-customer",
        component: () => import("@/pages/counselor/counselorCustomerManage.vue"),
      },
      {
        // 상담예약현황
        path: "reservation",
        name: "counselor-reservation",
        component: () => import("@/pages/counselor/reservation.vue"),
      },
      {
        // 상담예약현황
        path: "reservation_info",
        name: "counselor-reservationInfo",
        component: () => import("@/pages/counselor/reservationInfo.vue"),
      },
      {
        // 상담일지관리
        path: "journal",
        name: "counselor-journal",
        component: () => import("@/pages/counselor/journal.vue"),
      },
      {
        // 상담일지상세
        path: "journal_info",
        name: "counselor-journalInfo",
        component: () => import("@/pages/counselor/journalInfo.vue"),
      },
      {
        // 상담일지작성
        path: "journal_register",
        name: "counselor-journalRegister",
        component: () => import("@/pages/counselor/journalRegister.vue"),
      },
      {
        // 상담일지수정
        path: "journal_modify",
        name: "counselor-journalModify",
        component: () => import("@/pages/counselor/journalModify.vue"),
      },
      {
        // 급여내역
        path: "salary",
        name: "salary",
        component: () => import("@/pages/counselor/salary.vue"),
      },
      {
        // 상담통계
        path: "statistic",
        name: "statistic",
        component: () => import("@/pages/counselor/statistic.vue"),
      },
      {
        // 정보수정
        path: "modify_info",
        name: "modifyInfo",
        component: () => import("@/pages/counselor/modifyInfo.vue"),
      },
      {
        // 센터정보수정
        path: "self_center_manage_modify",
        name: "origin-center-centerManageModify",
        component: () =>import("@/pages/convention/center/centerManageModify.vue"),
      },
    ],
  },

  {
    // 협약센터
    path: "/convention",
    name: "Convention",
    component: () => import("@/pages/convention.vue"),
     beforeEnter: requirePath,

    children: [
      {
        // 상담사승인
        path: "confirmCounselor",
        name: "convention-confirmCounselor",
        component: () => import("@/pages/convention/counselor/confirmCounselor.vue"),
      },
      {
        // 상담일지관리
        path: "journal",
        name: "counselor-journal",
        component: () => import("@/pages/convention/journal.vue"),
      },
      {
        // 상담일지상세
        path: "journal_info",
        name: "counselor-journalInfo",
        component: () => import("@/pages/convention/journalInfo.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule",
        name: "convention-consultSchedule",
        component: () =>
          import("@/pages/convention/consult/consultSchedule.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule_button",
        name: "origin-consultScheduleButton",
        component: () => import("@/pages/convention/consult/consultScheduleButton.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule_register",
        name: "origin-consultScheduleRegister",
        component: () => import("@/pages/convention/consult/consultScheduleRegister.vue"),
      },
      {
        // 상담일정
        path: "consult_schedule_modify",
        name: "origin-consultScheduleModify",
        component: () => import("@/pages/convention/consult/consultScheduleModify.vue"),
      },
      {
        // 상담예약관리
        path: "consult_reserve",
        name: "convention-consultReserve",
        component: () =>
          import("@/pages/convention/consult/consultReserve.vue"),
      },
      {
        // 상담예약 상세정보
        path: "consult_reserve_detail",
        name: "convention-consultReserveDetail",
        component: () =>
          import("@/pages/convention/consult/consultReserveDetail.vue"),
      },
      {
        // 상담예약 등록
        path: "consult_reserve_register",
        name: "convention-consultReserveRegister",
        component: () =>
          import("@/pages/convention/consult/consultReserveRegister.vue"),
      },
      {
        // 상담예약 등록
        path: "consult_reserve_modify",
        name: "convention-consultReserveModify",
        component: () =>
          import("@/pages/convention/consult/consultReserveModify.vue"),
      },
      {
        // 센터정보수정
        path: "center_manage_modify",
        name: "convention-centerManageModify",
        component: () =>
          import("@/pages/convention/center/centerManageModify.vue"),
      },
      {
        // 상담사관리
        path: "counselor_manage",
        name: "convention-counselorManage",
        component: () =>
          import("@/pages/convention/counselor/counselorManage.vue"),
      },
      {
        // 상담사카드
        path: "counselor_manage_card",
        name: "convention-counselorManageCard",
        component: () =>
          import("@/pages/convention/counselor/counselorManageCard.vue"),
      },
      {
        // 상담사카드 등록
        path: "counselor_manage_register",
        name: "convention-counselorManageRegister",
        component: () =>
          import("@/pages/convention/counselor/counselorManageRegister.vue"),
      },
      {
        // 상담사카드 수정
        path: "counselor_manage_modify",
        name: "convention-counselorManageModify",
        component: () =>
          import("@/pages/convention/counselor/counselorManageModify.vue"),
      },
      {
        // 밝음상담관리
        path: "happy_consult_manage",
        name: "convention-happyConsultManage",
        component: () =>
          import("@/pages/convention/happy/happyConsultManage.vue"),
      },
      {
        // 밝음급여내역
        path: "happy_consult_salary",
        name: "convention-happyConsultSalary",
        component: () =>
          import("@/pages/convention/happy/happyConsultSalary.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  mode: "history",
  routes,
});

export default router;
