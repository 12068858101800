import Vue from "vue";
import "./plugins/axios";
import "@babel/polyfill";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import crmDatepicker from "@/components/utils/datePicker.vue";
import VueCryptojs from "vue-cryptojs"; // 암호화 모듈 호출
import { ValidationProvider, extend, localize } from "vee-validate";
import ko from "vee-validate/dist/locale/ko.json";
import { required } from "vee-validate/dist/rules";
// 구 밝음 CRM css 불러오기
import "./assets/css/css.css";
import "./assets/css/reset.css";

// 신규 css
import "./assets/css/common.css";

Vue.config.productionTip = false;

// 이벤트 버스 import없이 전역으로 사용하기 위함
Vue.prototype.$EventBus = new Vue();
Vue.use(VueCryptojs);

localize({
  ko,
});
localize("ko");
// DatePicker 컴포넌트 전역 설정
Vue.component("crmDatepicker", crmDatepicker);
Vue.component("ValidationProvider", ValidationProvider);
extend("required", required);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
